import React, { FC, useState } from 'react';
import Container from 'react-bootstrap/Container';

import { IQuestion, QuizQuestionType } from './models.d';
import QuizAnswer from './QuizAnswer';
import './Question.scss';

const Question: FC<IQuestion> = ({ question: { options, type }, setAnswer }) => {
  const [singleSelected, setSingleSelected] = useState<number | null>(null);
  const singleChoiceProps =
    type === QuizQuestionType.single ? { ...{ singleSelected, setSingleSelected } } : undefined;

  return (
    <Container fluid>
      <ul className="dt-quiz-question">
        {options.map(({ label: { text, title }, id, value }) => (
          <QuizAnswer
            key={`${id}${value}`}
            {...{ id, text, title, value, onClick: setAnswer }}
            {...singleChoiceProps}
          />
        ))}
      </ul>
    </Container>
  );
};

export default Question;
