import React, { FC } from 'react';

import ProductCarousel from 'components/common/ProductCarousel';
import { CarouselMasks } from 'components/common/ProductCarousel/models';
import QuizCarouselAnswer from './QuizCarouselAnswer';
import { IQuizCarousel } from './models';
import './QuizCarousel.scss';

const QuizCarousel: FC<IQuizCarousel> = ({ question: { options }, setAnswer }) => (
  <ProductCarousel
    partialVissible
    infinite={false}
    type={CarouselMasks.bothMasksAssistant}
    limitWidth={options.length < 4}
    isBtnGroup={options.length > 3}
  >
    {options.map(({ label: { text, image }, id, value }) => (
      <QuizCarouselAnswer key={id} {...{ id, image, text, value, onClick: setAnswer }} />
    ))}
  </ProductCarousel>
);

export default QuizCarousel;
