import React, { FC } from 'react';

import { IQuizResults } from './models.d';
import ProductResult from './ProductResult';
import ArticleResult from './ArticleResult';
import './QuizResults.scss';

const QuizResults: FC<IQuizResults> = ({ searchResults: { articles, products } }) => {
  return (
    <ul className="dt-quiz-results">
      {products.map(({ name, size, link = '#href', heroImage }) => (
        <ProductResult key={name} {...{ name, size, heroImage, link }} />
      ))}
      {articles.map(({ title, link, image, content }) => (
        <ArticleResult key={title} {...{ title, image, link, content }} />
      ))}
    </ul>
  );
};

export default QuizResults;
