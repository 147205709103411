import React, { FC } from 'react';

import CardBox from 'components/common/CardBox';
import { IArticleResult } from './models';
import './ArticleResult.scss';

const ArticleResult: FC<IArticleResult> = ({ title, image, link, content }) => (
  <li className="dt-quiz-results__article">
    <CardBox
      image={image}
      title={title}
      link={link}
      labelTop={{ type: 'primary', text: content, children: content }}
    />
  </li>
);

export default ArticleResult;
