import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';

import extractUrlFromMultiPicker from 'utils/urlMultiPicker';
import MediaInfoBox from 'components/common/MediaInfoBox/MediaInfoBox';
import Media from 'components/common/MediaInfoBox/Media';
import Info from 'components/common/MediaInfoBox/Info';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import Label from 'components/common/Label';
import HTag from 'components/common/HTag';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IPromoPanel } from './models';

const PanelTypes = {
  IMAGE: 'Image',
  VIDEO: 'Video',
};

const PromoPanel: FC<IPromoPanel> = ({
  promoProduct,
  buttonText,
  buttonURL,
  ariaLabel,
  descriptionText,
  titleText,
  imageAlt,
  boldTitle,
  labelText,
  panelType,
  panelImage,
  panelVideo,
  sectionLandmark,
  disclaimer,
  productImage,
  customImageToggle,
}) => {
  return (
    <MediaInfoBox
      isMask={panelType[0] === PanelTypes.IMAGE}
      imageCenter={promoProduct?.productImage}
      imageCenterAlt={promoProduct?.productName}
      sectionLandmark={sectionLandmark}
      productImage={productImage}
      customImageToggle={customImageToggle}
    >
      <>
        <Media video={panelType[0] === PanelTypes.VIDEO ? panelVideo : ''}>
          <UmbracoImage image={panelImage} alt={imageAlt} />
        </Media>

        <Info>
          <Label type="primary">{labelText}</Label>
          <HTag size={3} regularText={titleText} boldText={boldTitle} />
          <p className="dt-media-info-box__text">{descriptionText}</p>

          <div className="dt-media-info-box__info-btn">
            <Button href={extractUrlFromMultiPicker(buttonURL)} size="lg" aria-label={ariaLabel}>
              {buttonText}
            </Button>
          </div>
          <DangerouslySetInnerHtml
            className="dt-media-info-box__text-disclaimer"
            html={disclaimer}
          />
        </Info>
      </>
    </MediaInfoBox>
  );
};

export default PromoPanel;
