export enum QuizQuestionType {
  single = 'single',
  multiple = 'multiple',
  carousel = 'carousel',
}

export type QuizAnswer = {
  id: number;
  value: string;
  tags?: {
    id: number;
    value: string;
    label: {
      text: string;
      title?: string;
    };
  };
  link?: string;
  label: {
    text: string;
    title?: string;
  };
};

export type QuizQuestion = {
  options: QuizAnswer[];
  type: QuizQuestionType;
  key: string;
  label: string;
  subheading?: string;
  id: number;
  orderIndex?: number;
};

export interface IQuestion {
  question: QuizQuestion;
  setAnswer: (answer: CurrentAnswer) => void;
}
