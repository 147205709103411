import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';
import Label from 'components/common/Label';
import UmbracoImage from 'components/common/Image/UmbracoImage';

import { IFooterPromo } from './models';
import './FooterPromo.scss';

const FooterPromo: FC<IFooterPromo> = ({ didYouKnow, mask, swap }) => {
  const classes = classNames('dt-footer-promo', 'dt-container-wrapper', {
    'dt-footer-promo--swap': swap,
    'dt-footer-promo--mask': mask,
  });

  const btnItem = didYouKnow.buttonText ? (
    <Link
      className="btn btn-light"
      to={extractUrlFromMultiPicker(didYouKnow.buttonURL)}
      aria-label={didYouKnow.ariaLabel}
    >
      {didYouKnow.buttonText}
    </Link>
  ) : null;

  return (
    <section className={classes} aria-label={didYouKnow.sectionLandmark}>
      <div className="dt-footer-promo__img-holder">
        <UmbracoImage
          image={didYouKnow.didYouKnowBG}
          alt={didYouKnow.imageAlt}
          className="dt-footer-promo__img"
        />
      </div>

      <Container className="dt-footer-promo__content" fluid>
        <div className="dt-footer-promo__content-col">
          <strong className="dt-footer-promo__title">
            <Label>{didYouKnow.labelText}</Label>
          </strong>

          <div className="dt-footer-promo__content-holder">
            <p className="dt-footer-promo__text">{didYouKnow.descriptionText}</p>
            {btnItem}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FooterPromo;
