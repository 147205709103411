import React, { FC } from 'react';

import { IQuestionLabel } from './models';
import './QuestionLabel.scss';

const QuestionLabel: FC<IQuestionLabel> = ({ label, subheading, progress, numberOfSteps }) => {
  const stepSize = 100 / numberOfSteps;
  const styles = {
    left: progress && `${progress}%`,
    marginLeft: `${-stepSize}%`,
    width: `${stepSize}%`,
  };

  return (
    <div className="dt-question-label">
      {progress ? (
        <div className="dt-question-label__progress">
          <div className="dt-question-label__progress-bar" style={styles} />
        </div>
      ) : null}

      <div className="dt-question-label__holder">
        <span className="dt-question-label__title">{label}</span>
        {subheading ? <p>{subheading}</p> : null}
      </div>
    </div>
  );
};

export default QuestionLabel;
