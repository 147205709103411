import React, { FC, useState } from 'react';
import cx from 'classnames';

import IconCustom from 'components/common/IconCustom';
import { IQuizAnswer } from './models';

const QuizAnswer: FC<IQuizAnswer> = ({
  text,
  title,
  onClick,
  value,
  id,
  singleSelected,
  setSingleSelected,
}) => {
  const [isSelected, setSelected] = useState<boolean>(false);
  const className = cx('dt-quiz-question__answer', {
    'dt-quiz-question__answer--selected': setSingleSelected ? id === singleSelected : isSelected,
  });

  const icon = id === singleSelected || isSelected ? <IconCustom icon="tick_white_icon" /> : null;

  const handleClick = () => {
    onClick({ answerId: id, value });
    if (setSingleSelected) {
      setSingleSelected(id === singleSelected ? null : id);
    } else {
      setSelected((prevState) => !prevState);
    }
  };

  return (
    // eslint-disable-next-line
    <li className={className} onClick={handleClick}>
      {icon}
      {title || text}
    </li>
  );
};

export default QuizAnswer;
