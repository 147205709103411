import React, { FC } from 'react';
import { Link } from 'gatsby';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import IconCustom from 'components/common/IconCustom';
import { IQuizButtonGroup } from './models';
import './QuizButtonGroup.scss';

const QuizButtonGroup: FC<IQuizButtonGroup> = ({
  isShowResults,
  repeatQuiz,
  goBack,
  addAnswer,
  isShowBackButton,
  isNextButtonDisabled,
  isShowNextButton,
  categoryLink,
  buttons: { next, back, repeat },
  isResultsSuccess,
}) => (
  <div className="dt-quiz-button-group">
    <Container fluid>
      {isShowResults ? (
        <>
          {categoryLink?.link && isResultsSuccess ? (
            <Link className="btn btn-outline-light" to={categoryLink.link}>
              {categoryLink.text}
            </Link>
          ) : null}

          <Button variant="light" onClick={repeatQuiz}>
            {repeat}
          </Button>
        </>
      ) : (
        <>
          {isShowBackButton ? (
            <Button variant="outline-light" onClick={goBack}>
              <IconCustom icon="long_arrow_icon" />
              {back}
            </Button>
          ) : null}
          {isShowNextButton ? (
            <Button variant="light" onClick={addAnswer} disabled={isNextButtonDisabled}>
              {next}
            </Button>
          ) : null}
        </>
      )}
    </Container>
  </div>
);

export default QuizButtonGroup;
