import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import useQuiz from 'hooks/useQuiz';
import QuestionLabel from 'components/QuestionLabel';
import Overlay from 'components/common/Overlay';
import HTag from 'components/common/HTag';
import Question from './Question';
import QuizCarousel from './QuizCarousel';
import QuizResults from './QuizResults';
import QuizButtonGroup from './QuizButtonGroup';

import { INewQuiz } from './models';

import './NewQuiz.scss';

const NewQuiz: FC<INewQuiz> = (props) => {
  const {
    questions,
    buttons,
    title,
    searchResultsLabel,
    salsifyLang,
    loadingLabel,
    noResultsLabel,
    xOrigin,
  } = props;
  const {
    goBack,
    repeatQuiz,
    setAnswer,
    addAnswer,
    isLoading,
    isShowResults,
    isShowBackButton,
    isNextButtonDisabled,
    currentProgress,
    currentQuestion,
    numberOfSteps,
    categoryLink,
    searchResults,
  } = useQuiz(questions, salsifyLang, xOrigin);

  const isCarouselStep = currentQuestion.type === 'carousel';
  const QuestionComponent = isCarouselStep ? QuizCarousel : Question;

  if (isLoading) {
    // TODO: insert spinner
    return (
      <div className="dt-new-quiz">
        <Container fluid>
          <HTag size={2} regularText={title.regularText} boldText={title.boldText} />
          <QuestionLabel
            label={currentQuestion.label}
            subheading={currentQuestion.subheading}
            progress={currentProgress}
            numberOfSteps={numberOfSteps}
          />
          <div className="dt-new-quiz__loading">{loadingLabel}</div>
          <Overlay visible />
        </Container>
      </div>
    );
  }

  return (
    <div className="dt-new-quiz dt-container-wrapper">
      <Container fluid>
        <HTag size={2} regularText={title.regularText} boldText={title.boldText} />
      </Container>

      {isShowResults ? (
        <Container fluid>
          <QuestionLabel
            label={searchResults.isSuccess ? searchResultsLabel : noResultsLabel}
            progress={100}
            numberOfSteps={numberOfSteps}
          />
          {searchResults.isSuccess ? <QuizResults searchResults={searchResults} /> : null}
        </Container>
      ) : (
        <>
          <Container fluid>
            <QuestionLabel
              label={currentQuestion.label}
              subheading={currentQuestion.subheading}
              progress={currentProgress}
              numberOfSteps={numberOfSteps}
            />
          </Container>
          <QuestionComponent question={currentQuestion} setAnswer={setAnswer} />
        </>
      )}

      <QuizButtonGroup
        isShowResults={isShowResults}
        isResultsSuccess={searchResults.isSuccess}
        repeatQuiz={repeatQuiz}
        goBack={goBack}
        addAnswer={addAnswer}
        isShowBackButton={isShowBackButton}
        isNextButtonDisabled={isNextButtonDisabled}
        isShowNextButton={!isCarouselStep}
        categoryLink={categoryLink}
        buttons={buttons}
      />
    </div>
  );
};

export default NewQuiz;
